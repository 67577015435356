<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" :disabled="table.loading" @click="operation.add.click">添加配件加价率</en-button>
      <button-ajax type="primary" @click="operation.save.click">保存</button-ajax>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading">
          <en-table-column label="操作">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['PriceRangeDto'] }">
              <button-delete :method="table.operation.delete.click" :params="row"> 删除 </button-delete>
              <button-ajax link @click="table.operation.edit.click(row)">编辑</button-ajax>
            </template>
          </en-table-column>
          <en-table-column label="范围起" prop="initialAmount">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['PriceRangeDto'] }">
              <span v-if="!row.active">{{ row.initialAmount }}</span>
              <en-input v-else v-model="row.initialAmount" :loading="table.loading"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="范围止" prop="endAmount">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['PriceRangeDto'] }">
              <span v-if="!row.active">{{ row.endAmount }}</span>
              <en-input v-else v-model="row.endAmount" :loading="table.loading"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="值类型" prop="benefitType">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['PriceRangeDto'] }">
              <span v-if="row.active">
                <select-maintain
                  v-model="row.benefitType"
                  :ajax="{
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['BENTYPE'])
                  }"
                  :props="{ label: 'message', value: '' }"
                  value-key="code"
                  class="w-full"
                ></select-maintain
              ></span>
              <span v-else :loading="table.loading">{{ row.benefitType.message }}</span>
            </template>
          </en-table-column>
          <en-table-column label="范围值" prop="amount">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['PriceRangeDto'] }">
              <!-- <span v-if="row.benefitType?.code === 'P' || !row.active">{{row.amount }}%</span>
              <en-input v-else="row.active" v-model="row.amount" :loading="table.loading">%</en-input
            >
              <span v-if="row.benefitType?.code === 'F' || !row.active">{{row.amount }}</span>
              <en-input v-else="row.active" v-model="row.amount" :loading="table.loading"></en-input
            > -->
              <span v-if="!row.active">
                <span v-if="row.benefitType?.code === 'P'">{{formatPercent (row.amount )}}</span>
                <span v-if="row.benefitType?.code === 'F'">{{ row.amount }}</span>
              </span>
              <span v-if="row.active">
                <en-input v-model="row.amount" :loading="table.loading">%</en-input>
              </span>
            </template>
          </en-table-column>

          <en-table-column label="备注" prop="comment">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['PriceRangeDto'] }"
              ><span v-if="!row.active"> {{ row.comment }}</span
              ><en-input v-else v-model="row.comment" :loading="table.loading"></en-input
            ></template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            if (this.table.data.find((item) => !item.id)) return
            this.table.data.push({ initialAmount: '', endAmount: '', benefitType: { message: '' }, amount: '', comment: '', active: true })
          }
        },
        save: {
          async click() {
            await this.table.submit()
            return this.table.get()
          }
        }
      },

      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/range',
            data: 'array',
            loading: false,
            convert(data: any) {
              data.forEach((item: any, index: Number) => {
                item.active = false
              })
            }
          },

          submit: {
            action: 'PUT /enocloud/common/range',
            loading: true,
            params(params) {
              params.data = this.table.data
            }
          }
        },
        children: {
          operation: {
            delete: {
              async click(index: number) {
                this.table.data.splice(index, 1)
                return this.table.get()
              }
            },
            edit: {
              click(row: EnocloudCommonDefinitions['PriceRangeDto']) {
                this.table.data.forEach((item) => {
                  item.active = false
                })
                row.active = true
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
